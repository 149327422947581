<template>
  <div class="sec3_OnlineMember">
      <div :class="['sec3_OnlineMember-inr', { 'view-more-active': isActive }]" :style="contentStyle">
      <!-- <audio muted autoplay="" id="test"></audio> -->
      <div id="mixedaudio"></div>
    <div id="toggle_cont_info">
      <!-- <v-container
        fluid
        class="d-inline-flex light-green lighten-5 pl-5 pr-5 pt-3"
        id="toggle_cont_info"
        >
      </v-container> -->
    </div>
    </div>
  <div id="showButton" class="btn-wrapper text-center">
    <button @click="toggleClass" class="btn custom-btn pattern-btn">
      <span v-if="!isActive">View More<img class="ml-2" src="../assets/images/arrow-down.svg" alt="" ></span>
      <span v-if="isActive">View Less<img class="ml-2" src="../assets/images/arrow-up.svg" alt="" ></span>
    </button>
  </div>
  </div>
</template>

<script>
import { BROADCASTING } from "@/constants/services";
import { mapGetters } from "vuex";
export default {
  name: "CurrentBroadcasts",
  data() {
    return {
      broadCastingService: 0,
      isActive: false,
      isExpanded: false
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    
    showButton() {
      setTimeout(() => {
      const container = document.getElementById('toggle_cont_info');
      console.re.log("containerfsddfsdsdsdsdsdsdsdsdsd " + container.children.length);
      return container && container.children.length > 1;
      // const childrenCount = container ? container.children.length : 0;
      // if(window.innerWidth <= 767 && childrenCount >= 1){
      //   return true;
      // }else if(childrenCount >3){
      //   return true;
      // }
      // return false
    }, 3000);
    },

    contentStyle() {
      return {
        maxHeight: this.isExpanded ? 'initial' : '115px', // Adjust as needed
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-out'
      };
    }

  },
  mounted() {
    this.broadCastingService = this.user.services.findIndex(
      (val) => val.id === BROADCASTING
    );
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive;
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
