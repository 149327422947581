<template>
  <v-footer absolute class="pa-0 footer-height blue-grey darken-4">
    <v-container fluid  class="pa-0 fill-height">
      <!-- <v-row class="pd-5 pr-2 pl-2 main-footer fill-height">
        <v-col cols="12" sm="2" md="2" class="pa-0 align-self-center" >
          <div class="d-flex justify-center online-counter">
              <div class="d-flex online-members">
                <i>Online Members</i> <span id="participantCount"> 0 </span>
              </div>
          </div>
        </v-col>
        <v-col cols="12" sm="7" md="7" class="pa-0 align-self-center">
          <div class="d-flex align-center justify-space-around">
            <a :href="banner.link" target="_blank" v-for="banner of banners">
              <img class="footerimage" :src="getImgUrl(banner.image)" />
            </a>
          </div>
        </v-col>
        <v-col class="pa-0 align-self-center d-flex justify-center" cols="12" sm="3" md="3">
          <a class="icon icon-fb rounded mr-2" :href="facebookLink" target="_blank">
            <img src="../../assets/facebook.png" />
          </a>
          <a class="icon icon-instagram rounded mr-2" :href="instagramLink" target="_blank">
            <img src="../../assets/instagram.png" />
          </a>
          <a :href="youtubeLink" class="icon icon-youtube rounded mr-2" target="_blank">
            <img src="../../assets/youtube.png" />
          </a>
          <a :href="tiktokLink" class="icon icon-website rounded mr-2" target="_blank">
            <img src="../../assets/tiktok.png" />
          </a>
          <a :href="websiteLink" class="icon icon-website rounded mr-2" target="_blank">
            <img src="../../assets/website.png" />
          </a>
          <a class="icon icon-wp rounded mr-2" color="white " :href="whatsappLink" target="_blank">
            <img src="../../assets/whatsapp.png" />
          </a>
        </v-col>
      </v-row> -->
      <v-dialog
      :value="paymentDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">The period of your subscription has passed. <br>Kindly make your monthly payment. </v-card-title>
        <v-card-text class="vcardtext">
          <v-alert v-if="paymentInfo?.has_failed_payment" dense outlined type="error">
            {{ paymentInfo?.failed_payment_reason ?? '' }}</v-alert>
            <br>
            <p style="font-size: 18px;">In case of any error or query please contact Adriano on 714-334-5505</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            class="green darken-4 green-gradient white--text"
            @click="
              dialog = false;
              $router.push('/payment-details');
            "
          >
            {{ $t("payment.paynow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </v-footer>  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventBus from "../../bus/eventBus";
import { RECURRING_BY_CARD, MONTHLY_BY_CARD } from "@/constants/common";

export default {
  name: "Footer",
  data() {
    return {
      facebookLink: process.env.VUE_APP_FACEBOOK_LINK,
      instagramLink: process.env.VUE_APP_INSTAGRAM_LINK,
      youtubeLink: process.env.VUE_APP_YOUTUBE_LINK,
      twitterLink: process.env.VUE_APP_TWITTER_LINK,
      websiteLink: process.env.VUE_APP_WEBSITE_LINK,
      whatsappLink: process.env.VUE_APP_WHATSAPP_LINK,
      tiktokLink: process.env.VUE_APP_TIKTOK_LINK,
      banners: [],
      paymentDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      paymentInfo: "user/getPaymentStatus",
    }),
  },
  async mounted() {
    EventBus.$on("PARTICIPANT_COUNT", function (count) {
      sessionStorage.setItem("onlineUsers", Number(count));
      console.log("onlineUsers=============>" + count);
      document.getElementById("participantCount").innerText = Number(count);
    });
    await this.randomFooterBanner();

    this.$root.$on('updateFooterBanner', () => {
      setTimeout(() => {
        this.randomFooterBanner();
      }, 500);
    })

    this.$root.$on('checkPaymentStatus', () => {
      setTimeout(() => {
        this.checkPaymentStatus();
      }, 500);
    })
  },
  methods: {
    ...mapActions({
      getPaymentStatus: "user/getPaymentStatus"
    }),
    async randomFooterBanner() {
      var allBanners = [{ image: 'rdl-marketplace.gif', link: 'https://redlineusedautoparts.com/' },{ image: 'upload-inv.gif', link: 'https://inventory.redlineusedautoparts.com/' }, { image: 'Comms-Banner.png', link: 'https://www.goteleworks.com/' }, { image: 'FedericoUrias.jpg', link: 'javascript:void(0);' }]
      this.banners = this.getMultipleRandom(allBanners, 2);
    },
    getMultipleRandom(arr, num) {
      const shuffled = [...arr].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    },
    getImgUrl(pet) {
      var images = require.context('../../assets/', false);
      return images('./' + pet)
    },
    async checkPaymentStatus() {
      await this.getPaymentStatus();
      console.log("this.paymentInfo: ", this.paymentInfo);
      if (
        this.paymentInfo &&
        !this.paymentInfo.is_subscribed &&
        !this.paymentInfo.is_on_grace_period
      ) {
        console.log("checkPaymentStatus is_subscribed: false, is_on_grace_period:false");
        this.paymentDialog = true;
        // this.$router.push({ name: "PaymentDetails" });
      } else if (
        this.paymentInfo &&
        this.paymentInfo.has_failed_payment &&
        !this.paymentInfo.is_on_grace_period &&
        [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
          this.paymentInfo.payment_method
        )
      ) {
        console.log("checkPaymentStatus has_failed_payment: true, is_on_grace_period:false");
        this.paymentDialog = true;
        // this.$router.push({ name: "PaymentDetails" });
      } else if(
        this.paymentInfo &&
        this.paymentInfo.has_failed_payment &&
        this.paymentInfo.is_on_grace_period &&
        [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
          this.paymentInfo.payment_method
        )) {
          console.log("checkPaymentStatus has_failed_payment: true, is_on_grace_period: true");
          this.paymentDialog = true;
      }
    }
  }
};
</script>
