<template>
  <div class="fill-height">
    <Navbar />
    <CurrentBroadcasts />
    <v-dialog :value="paymentError" persistent max-width="490">
      <v-card>
        <v-card-title class="headline mb-4 pl-4 red--text"
          ><v-layout justify-center>
            <h3 class="p-5 justify-center">
              {{ $t("payment.paymentError") }}
            </h3>
          </v-layout></v-card-title
        >
        <v-card-text class="vcardtext mt-4 mb-4"
          ><v-layout justify-center
            ><h2>{{ error }}</h2></v-layout
          >
          <!-- {{ user.grace_period_days }}
          {{ $t("payment.days") }}-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            class="green darken-4 green-gradient white--text"
            @click="payAgain()"
          >
            {{ $t("payment.payAgain") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main class="custom-height panel-left light-green lighten-5">
      <v-container fluid class="light-green lighten-5 pt-5 pl-5 pr-5 pb-5">
        <v-row align="center" justify="center">
          <v-col primary xs="12" sm="5">
            <v-card class="d-flex flex-column" max-height="300px">
              <!-- <v-card-title
                class="chat-header"
                v-if="!hasCashOrChequeMethod && !isAssociate"
              >
                <label>
                  <span v-if="loader && !saving">
                    {{ $t("payment.paymentPageLoading") }}
                  </span>
                  <span v-else>{{
                    updatePaymentMethod
                      ? $t("payment.payHere")
                      : $t("payment.currentPaymentMethod")
                  }}</span>
                </label>
              </v-card-title> -->
              <span v-if="!hasCashOrChequeMethod && !isAssociate">
                <v-tabs
                    v-model="tab"
                    class="chat-header"
                    style="min-height: unset !important;"
                  >
                    <v-tab value="one" @click="changePaymentMethod" v-if="hasPaymentMethod && currentPaymentMethod">{{ $t("payment.payByCurrentPaymentMethod") }}</v-tab>
                    <v-tab value="two" @click="changePaymentMethod">{{ $t("payment.payByNewCard") }}</v-tab>
                  </v-tabs>
              
                  <v-card-text>
                    <v-window>
                      <v-window-item value="one" v-if="
                        hasPaymentMethod &&
                        currentPaymentMethod &&
                        !updatePaymentMethod
                      ">
                        <v-card-text
                          style="margin-top: 25px">
                          <v-text-field
                            id="card-holder-name"
                            type="text"
                            hide-details="auto"
                            outlined
                            :label="$t('Card Holder Name')"
                            color="green darken-4"
                            class="mb-4"
                            :value="currentPaymentMethod.billing_details.name"
                            disabled="disabled"
                          ></v-text-field>

                          <div>
                            <i
                              class="text-3xl fab"
                              :class="'fa-cc-' + currentPaymentMethod.card.brand"
                              style="font-size: 1.30rem !important;"
                            />

                            <span class="text-xs" style="font-size: 1.30rem !important;">
                              &bull; &bull; &bull; &bull;
                              {{ currentPaymentMethod.card.last4 }}
                              ({{ currentPaymentMethod.card.exp_month }}
                              /
                              {{ currentPaymentMethod.card.exp_year }})
                            </span>
                          </div>
                          <v-btn
                            color="green accent-4"
                            class="mr-3 mt-5 green darken-4 green-gradient white--text"
                            @click="chargeDuePayment"
                            :disabled="paymentButton"
                            style="font-size: 1.30rem !important;"
                          >
                            Pay ${{ amount }}
                          </v-btn>
                        </v-card-text>
                      </v-window-item>
                      <v-window-item value="two" v-if="updatePaymentMethod">
                        <v-card-text
                          style="margin-top: 10px">
                          <v-text-field
                            id="card-holder-name"
                            type="text"
                            hide-details="auto"
                            outlined
                            :label="$t('Card Holder Name')"
                            color="green darken-4"
                            class="mb-4"
                          ></v-text-field>

                          <!-- Stripe Elements Placeholder -->
                          <div class="stripeElement" id="card-element"></div>
                          <div class="error-msg" v-if="cardError !== ''">
                            {{ cardError }}
                          </div>
                          <v-btn
                            color="green accent-4"
                            class="mr-3 mt-2 green darken-4 green-gradient white--text"
                            id="card-button"
                            :loading="saving"
                            :disabled="saving"
                            :data-secret="setupIntent.client_secret"
                            v-if="setupIntent"
                            style="font-size: 1.30rem !important;"
                          >
                            <span v-if="user.is_new_user">Pay ${{ amount }} </span>
                            <span v-else> Pay ${{ amount }} </span>
                          </v-btn>
                        </v-card-text>
                      </v-window-item>
                    </v-window>
                  </v-card-text>
              </span>
              <span v-else>
                <v-card-title>
                  <label>
                    <span class="center-image"
                      ><img
                        src="../assets/unautorized.svg"
                        alt=""
                        height="150px"
                    /></span>
                    <h6 class="ml-2 mt-4" v-if="hasCashOrChequeMethod">
                      {{ $t("payment.hasCashOrChequeMethod") }}
                    </h6>
                    <h6 class="ml-10 mt-4 text-center" v-if="isAssociate">
                      {{ $t("payment.associatePaymentAlert") }}
                    </h6>
                  </label>
                </v-card-title>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import { mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import {
  MONTHLY_BY_CARD,
  RECURRING_BY_CARD,
  CASH,
  CHEQUE,
} from "@/constants/common";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
  },

  data() {
    return {
      saving: false,
      intentToken: null,
      currentPaymentMethod: null,
      updatePaymentMethod: false,
      cardError: "",
      paymentError: false,
      error: "",
      tab: null,
      paymentButton: false
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      setupIntent: "payment/setupIntent",
      paymentMethod: "payment/defaultPaymentMethod",
      loader: "getLoaderStatus",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
    hasPaymentMethod() {
      return !!this.paymentMethod;
    },
    isCard() {
      return [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
        this.user.user_subscription.payment_method.name
      );
    },
    amount() {
      return this.user.user_subscription.amount + this.user.pending_car_history_payment;
    },
    hasCashOrChequeMethod() {
      return [CASH, CHEQUE].includes(this.user.payment_method);
    },
  },
  async mounted() {
    await this.fetchPaymentMethod();
  },
  methods: {
    ...mapActions({
      fetchSetupIntent: "payment/setupIntent",
      fetchDefaultPaymentMethod: "payment/defaultPaymentMethod",
      savePaymentMethod: "payment/addPaymentMethod",
      getProfile: "user/getProfile",
      addDuePayment: "payment/addDuePayment",
    }),
    async chargeDuePayment() {
      this.paymentButton = true;
      this.$store.commit("setLoader", true);
      try {
        await this.addDuePayment();
        await this.getProfile();
        this.$router.push({ name: "Dasboard" });
      } catch ({ message }) {
        this.paymentButton = false;
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async changePaymentMethod() {
      this.updatePaymentMethod = !this.updatePaymentMethod;
      if(this.updatePaymentMethod) {
        await this.initSetupIntent();
        await this.setupCardElement();
      } else {
        await this.fetchPaymentMethod();
      }
    },
    async fetchPaymentMethod() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchDefaultPaymentMethod();
        this.currentPaymentMethod = this.paymentMethod;
        if (this.currentPaymentMethod === null) {
          await this.changePaymentMethod();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async initSetupIntent() {
      this.$store.commit("setLoader", true);
      try {
        await this.fetchSetupIntent();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async setupCardElement() {
      this.$store.commit("setLoader", true);
      try {
        // SETUP CARD ELEMENT
        const stripe = await loadStripe(process.env.VUE_APP_PUBLIC_KEY);

        const elements = stripe.elements({
          fonts: [
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
            },
          ],
        });

        const elementStyles = {
          base: {
            color: "#32325D",
            fontWeight: 500,
            fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#7f7f8c",
            },
            ":-webkit-autofill": {
              color: "#7f7f8c",
            },
          },
          invalid: {
            color: "#E25950",

            "::placeholder": {
              color: "#7f7f8c",
            },
          },
        };

        const elementClasses = {
          focus: "focused",
          empty: "empty",
          invalid: "invalid",
        };

        const cardElement = elements.create("card", {
          hidePostalCode: true,
          style: elementStyles,
          classes: elementClasses,
        });

        cardElement.mount("#card-element");

        // VERIFY CARD DETAIL
        const cardHolderName = document.getElementById("card-holder-name");
        const cardButton = document.getElementById("card-button");
        const clientSecret = cardButton.dataset.secret;
        // const clientSecret = this.setupIntent.client_secret;
        cardButton.addEventListener("click", async () => {
          this.saving = true;
          const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: { name: cardHolderName.value },
              },
            }
          );

          if (error) {
            this.saving = false;
            // this.cardError = error.message;
            this.error = error.message;
            this.paymentError = true;
            // Display "error.message" to the user...
            console.log(error);
          } else {
            // The card has been verified successfully...
            await this.saveCardDetails(setupIntent);
          }
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },

    payAgain() {
      // this.$store.commit("setLoader", true);
      // window.location.reload();
      this.paymentError = false;
    },

    async saveCardDetails(setupIntent) {
      this.$store.commit("setLoader", true);
      try {
        await this.savePaymentMethod({
          payment_method: setupIntent.payment_method,
        });
        this.updatePaymentMethod = false;
        await this.fetchPaymentMethod();
        await this.getProfile();
        this.$router.push({ name: "Dashboard" });
      } catch ({ message }) {
        this.error = message.custom[0];
        if (message.custom) {
          this.paymentError = true;
        }
        console.log(message);
      } finally {
        this.saving = false;
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
<style>
.v-tab--active {
  background-color: #2a9031 !important;
  border-color: #2a9031 !important;
  color: #fff !important;
}
</style>
